import React, {Component} from "react";

export default class ContactFormAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {visible: true};
        this.onCloseButtonClicked = this.onCloseButtonClicked.bind(this);
    }

    render() {
        if (!this.state.visible) {
            return '';
        }
        return (
            <div className={this.props.alertClassName + ' contact-form-alert'}>
                <span className={this.props.alertCloseButtonClassName + ' contact-form-alert-close-button'}
                      onClick={this.onCloseButtonClicked}>&times;</span>
                {this.props.message}
            </div>
        );
    }

    onCloseButtonClicked() {
        this.setState({visible: false}); // hide alert
        this.props.callback(); // reset ContactForm to be usable again
    }
}