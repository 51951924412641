import React, {Component} from "react";
import {withTranslation} from "react-i18next";

class SubjectInput extends Component {

    constructor(props) {
        super(props);
        this.state = {subject: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({subject: event.target.value});
        // this.props.subject(event.target.value);
    }

    render() {
        const { t } = this.props;

        return (
            <input type={'text'} name={'subject'} placeholder={t('contact.form.subject')} onChange={this.handleChange}  required={'required'}/>
        )
    }
}

export default withTranslation()(SubjectInput);