import React, {Component} from 'react';
import Subtitle from "./subtitle";
import Title from "./title";

export default class Home extends Component {
    render() {
        return (
            <div ref={this.props.element}>
                <div ref={this.props.refs.Home} id="home" className="intro bg-image">
                    <div className="intro-content display-table">
                        <div className="table-cell">
                            <div className="container">
                                <Title/>
                                {/* Trick! Set global i18n setting of suspense to false and just use it here,
                                    then it works. So maybe it should be as low as possible in the tree and not
                                    as high as possible...
                                */}
                                <Subtitle useSuspense={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}