import React, {Component} from 'react';

export default class Profile extends Component {
    render() {
        return (
            <div className="profile">
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a href="https://www.facebook.com/max.erler.12/" target={'_blank'} rel={'noreferrer'} className="fa-background fa-facebook-background"><div className={"fa fa-facebook"}/></a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a href="https://twitter.com/MaxErler9" target={'_blank'} rel={'noreferrer'} className="fa-background fa-twitter-background"><div className={"fa fa-twitter"}/></a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a href="https://www.linkedin.com/in/max-erler-7589821b4/" target={'_blank'} rel={'noreferrer'} className="fa-background fa-linkedin-background"><div className={"fa fa-linkedin"}/></a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a href="https://www.instagram.com/hollerkini/" target={'_blank'} rel={'noreferrer'} className="fa-background fa-instagram-background"><div className={"fa fa-instagram"}/></a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a href={'https://www.treedom.net/de/user/max-erler'} target={'_blank'} rel={'noreferrer'} className="fa-background fa-tree-background"><div className={"fa fa-tree"}/></a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a href={'https://github.com/maximotus'} target={'_blank'} rel={'noreferrer'} className="fa-background fa-github-background"><div className={"fa fa-github"}/></a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a href={'https://gitlab.com/Maximotus'} target={'_blank'} rel={'noreferrer'} className="fa-background fa-gitlab-background"><div className={"fa fa-gitlab"}/></a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a href={'https://stackoverflow.com/users/13720545/maximotus'} target={'_blank'} rel={'noreferrer'} className="fa-background fa-stack-overflow-background"><div className={"fa fa-stack-overflow"}/></a>
            </div>
        );
    }
}