import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class ServicesDescription extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="services-description">
                <div className="services-description-row">
                    <div className="services-description-col">
                        <div className="services-description-item">
                            <h2>
                                <b>{t('services.label')}</b>
                            </h2>
                            <p>
                                {t('services.text')}
                            </p>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ServicesDescription);