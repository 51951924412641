import React, {Component} from "react";
import {withTranslation} from "react-i18next";

class MessageInput extends Component {

    constructor(props) {
        super(props);
        this.state = {message: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({message: event.target.value});
        // this.props.message(event.target.value);
    }

    render() {
        const { t } = this.props;

        return (
            <textarea name="message" placeholder={t('contact.form.message')} onChange={this.handleChange}  required={'required'}/>
        )
    }
}

export default withTranslation()(MessageInput);