import React, {Component} from 'react';
import ContactForm from "./contactForm";
import {Trans, withTranslation} from "react-i18next";

// TODO insert icons, see https://stackoverflow.com/questions/917610/put-icon-inside-input-element-in-a-form
class Contact extends Component {
    render() {
        const { t } = this.props;

        return (
            <section ref={this.props.refs.Contact} id="contact" className={'contact'}>
                <div className={'container'}>
                    <div className={'contact-row'}>
                        <div className={'contact-col'}>
                            <div className={'contact-description'}>
                                <h2>{t('contact.label')}</h2>
                                <p>
                                    <Trans i18nKey={'contact.text'}>
                                        If you have questions, you'd like to hire me or want to send me feedback to the
                                        website,
                                        please use the following contact form or <a href={'mailto:contact@maxerler.com'}>send me an email</a>.
                                        In urgent cases you can also call me!
                                    </Trans>
                                </p>
                                <hr/>
                            </div>
                        </div>
                        <div className={'contact-col'}>
                            <div className={'contact-form'}>
                                <ContactForm/>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation()(Contact);