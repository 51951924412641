import React, {Component} from 'react';
import ProjectsDescription from "./projectsDescription";
import ProjectsItems from "./projectsItems";

export default class Projects extends Component {
    render() {
        return (
            <section ref={this.props.refs.Projects} id="projects">
                <div className="container">
                    <div className="box-shadow-full">
                        <ProjectsDescription/>
                        <ProjectsItems/>
                    </div>
                </div>
            </section>
        );
    }
}