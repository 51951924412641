import React, {Component} from 'react';

export default class Title extends Component {
    render() {
        return (
            <div id="title">
                <h1 className="intro-title mb-4">It's ME, Max Erler</h1>
            </div>
        );
    }
}