import React, {Component} from 'react';
import ReactTyped from "react-typed";
import {withTranslation} from "react-i18next";

class Subtitle extends Component {
    render() {
        const {t} = this.props;
        return (
            <div id="subtitle">
                <ReactTyped
                    className={'intro-subtitle'}
                    strings={[
                        t('full_stack_software_development.label'),
                        t('web_development.label'),
                        t('frontends.label'),
                        t('backends.label'),
                        t('it_consulting.label')]}
                    typeSpeed={70}
                    backSpeed={10}
                    loop
                />
            </div>
        );
    }
}

export default withTranslation()(Subtitle);