import {useEffect, useState, useRef} from "react"

// TODO possible improvement: https://www.pluralsight.com/tech-blog/getting-size-and-position-of-an-element-in-react/
function useSticky() {
    const [isSticky, setSticky] = useState(false);
    const element = useRef(null);
    const triggerHeight = window.innerHeight;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleScroll = () => {
        if (element.current){
            window.scrollY + triggerHeight > element.current.getBoundingClientRect().bottom // + (size.height - 50) because the navigation bar should stick earlier
                ? setSticky(true)
                : setSticky(false)
        }
    };

    // This function handle the scroll performance issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounce = (func, wait = 0, immediate = true) => {
        let timeOut;
        return () => {
            let context = this,
                args = arguments;
            const later = () => {
                timeOut = null;
                if (!immediate) func.apply(context, args)
            };
            const callNow = immediate && !timeOut;
            clearTimeout(timeOut);
            timeOut = setTimeout(later, wait);
            if (callNow) func.apply(context, args)
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", debounce(handleScroll));
        return () => {
            window.removeEventListener("scroll", () => handleScroll)
        }
    }, [debounce, handleScroll]);

    return {isSticky, element}
}

export default useSticky