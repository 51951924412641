import React, {Component} from 'react';
import {Trans, withTranslation} from "react-i18next";

class ProfileText extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="profile-text">
                <h2><b>{t('about.label')}</b></h2>
                <span>
                     <p>
                         <Trans i18nKey={'about.text.paragraph1'}>
                             I am an enthusiastic <b>freelance software developer</b>, a highly interested <b>computer science master student with focus on topics around data science</b> at the&#160;
                             <a target={'_blank'} rel={'noreferrer'} href="https://www.uni-muenchen.de/index.html">LMU Munich</a> and a music-loving <b>guitarist</b>!
                         </Trans>
                         <br/>
                         <br/>
                         <Trans i18nKey={'about.text.paragraph2'}>
                             Check out the <a target={'_blank'} rel={'noreferrer'} href={'https://github.com/TemporalKGTeam/tkg-framework'}>GitHub repository</a> corresponding to my <b>bachelor thesis</b> on the topic
                             "Investigating temporal knowledge graph embedding models in a unified framework"!
                         </Trans>
                         <br/>
                         <br/>
                         <Trans i18nKey={'about.text.paragraph3'}>
                             I have gained a lot of <b>coding experience</b> at the <a target={'_blank'} rel={'noreferrer'} href="https://www.doubleslash.de/">doubleSlash Net-Business GmbH</a> doing an internship as
                             well as a working student job.
                             Also, I have gained a lot of experiences founding an <b>own company</b>: <a target={'_blank'} rel={'noreferrer'} href="https://www.hollerkini.com/">Hollerkini</a>.
                         </Trans>
                     </p>
                    </span>
            </div>
        );
    }
}

export default withTranslation()(ProfileText);