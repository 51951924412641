import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import LanguagePicker from "./languagePicker";

/**
 * This class represents the dropdown menu in mobile view.
 */
class DropdownMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuVisible: false
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    handleClick() {
        if (!this.state.menuVisible) {
            document.addEventListener('click', this.handleOutsideClick, false);
            this.props.callback(true);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
            this.props.callback(false);
        }

        this.setState(stateBefore => ({
            menuVisible: !stateBefore.menuVisible,
        }));
    }

    handleOutsideClick(event) {
        if (this.node.contains(event.target)) {
            return;
        }

        this.handleClick();
    }

    render() {
        const { t } = this.props;
        const items =
            <div className={'dropdown-items'}>
                <button onClick={() => this.props.goTo('Home')}
                        className={(this.props.activeElement === 'Home' ? "active-mobile-page " : "")}>
                    <span>{t('home.label')}</span>
                </button>
                <button onClick={() => this.props.goTo('About')}
                        className={(this.props.activeElement === 'About' ? "active-mobile-page " : "")}>
                    <span>{t('about.label')}</span>
                </button>
                <button onClick={() => this.props.goTo('Services')}
                        className={(this.props.activeElement === 'Services' ? "active-mobile-page " : "")}>
                    <span>{t('services.label')}</span>
                </button>
                <button onClick={() => this.props.goTo('Projects')}
                        className={(this.props.activeElement === 'Projects' ? "active-mobile-page " : "")}>
                    <span>{t('projects.label')}</span>
                </button>
                <button onClick={() => this.props.goTo('Contact')}
                        className={(this.props.activeElement === 'Contact' ? "active-mobile-page " : "")}>
                    <span>{t('contact.label')}</span>
                </button>
                <div className={'language-picker-mobile'}><LanguagePicker /></div>
            </div>;

        return (
            <div className={'dropdown-menu' + (this.state.menuVisible ? ' dropdown-menu-open' : '')}
                 ref={node => this.node = node}>
                <button className={'hamburger hamburger--elastic' + (this.state.menuVisible ? ' is-active' : '')}
                        onClick={this.handleClick} type={'button'}>
                    <span className="hamburger-box">
                        <span
                            className={'hamburger-inner' + (this.state.menuVisible || this.props.sticky ? ' hamburger-inner-sticky' : '')}>
                        </span>
                    </span>
                </button>
                {this.state.menuVisible ? items : undefined}
            </div>
        );
    }
}

export default withTranslation()(DropdownMenu);