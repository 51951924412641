import React, {Component} from 'react';
import ServicesDescription from "./servicesDescription";
import ServicesItems from "./servicesItems";

export default class Services extends Component {
    render() {
        return (
            <section ref={this.props.refs.Services} id="services">
                <div className="container">
                    <div className="box-shadow-full">
                        <ServicesDescription/>
                        <ServicesItems/>
                    </div>
                </div>
            </section>
        );
    }
}