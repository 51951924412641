import React, {Component} from 'react';
import MobileNavigation from "./mobileNavigation";
import DesktopNavigation from "./desktopNavigation";

export default class Navigation extends Component {

    render() {
        return (
            <div>
                <MobileNavigation
                    refs={this.props.refs}
                    activeElement={this.props.activeElement}
                    goTo={this.props.goTo}
                    sticky={this.props.isSticky}/>
                <DesktopNavigation
                    refs={this.props.refs}
                    activeElement={this.props.activeElement}
                    goTo={this.props.goTo}
                    sticky={this.props.isSticky}/>
            </div>
        );
    }
}