import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class LanguagePicker extends Component {
    basePathLocales = 'locales/';
    basePathImages = 'images/';
    deCode = 'de';
    enCode = 'en';
    dePath = this.deCode + '/';
    enPath = this.enCode + '/';
    flagFileName = 'flag.png';
    checkmarkFileName = 'green-checkmark.png';

    constructor(props) {
        super(props);
        const { i18n } = this.props;
        this.state = {
            activeLanguage: i18n.language
        }
    }

    handleClick(code) {
        const { i18n } = this.props;
        i18n.changeLanguage(code);
        console.log('Change active language from ' + this.state.activeLanguage + ' to ' + code);
        this.setState(() => ({
            activeLanguage: code,
        }));
    }

    render() {
        return (
            <div className={'language-picker'}>
                <button className={'language-picker-button'} type={'button'}
                        onClick={() => this.handleClick(this.enCode)}>
                    <div className={'language-picker-flag-container'}>
                        <img
                            className={'language-picker-flag'}
                            src={this.basePathLocales + this.enPath + this.flagFileName}
                            alt={'International flag'}
                        />
                        <img
                            className={(this.state.activeLanguage === this.enCode ? 'language-picker-checkmark-visible' : 'language-picker-checkmark-invisible')}
                            src={this.basePathImages + this.checkmarkFileName}
                            alt={'Green checkmark'}
                        />
                    </div>
                </button>
                <button className={'language-picker-button'} type={'button'}
                        onClick={() => this.handleClick(this.deCode)}>
                    <div className={'language-picker-flag-container'}>
                        <img className={'language-picker-flag'}
                             src={this.basePathLocales + this.dePath + this.flagFileName}
                             alt={"German flag"}
                        />
                        <img
                            className={(this.state.activeLanguage === this.deCode ? 'language-picker-checkmark-visible' : 'language-picker-checkmark-invisible')}
                            src={this.basePathImages + this.checkmarkFileName}
                            alt={'Green checkmark'}
                        />
                    </div>
                </button>
            </div>
        );
    };
}

export default withTranslation()(LanguagePicker);
