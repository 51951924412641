import './App.scss';
import {BrowserRouter} from "react-router-dom";
import React from "react";
import useSticky from "./hooks/useSticky";
import Routing from "./components/routing";

function App() {
    const {isSticky, element} = useSticky();
    return (
        <div className="App" id="website">
            <BrowserRouter>
                <Routing element={element} isSticky={isSticky}/>
            </BrowserRouter>
        </div>
    );
}

export default App;
