import React, {Component} from 'react';
import Profile from "./profile";
import ProfileText from "./profileText";
import ProfileImage from "./profileImage";
import Skills from "../about/skills";

export default class About extends Component {
    render() {
        return (
            <section ref={this.props.refs.About} id="about" className="about">
                <div className="container">
                    <div className="row">
                        <div className="box-shadow-full">
                            <div className="all-information">
                                <div className="information">
                                    <div className="image-and-profile">
                                        <ProfileImage/>
                                        <Profile/>
                                    </div>
                                    <div className="information-skills">
                                        <Skills/>
                                    </div>
                                </div>
                                <div className="information-text">
                                    <ProfileText/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}