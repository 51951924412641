import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class ProfileImage extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="profile-image-container">
                <div className={'flip-card flip-card-profile'}>
                    <div className={'flip-card-inner'}>
                        <div className={'flip-card-front'}>
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img className={'profile-image'} src={"images/max-erler-in-suit.jpeg"} alt={"My profile picture."}/>
                        </div>
                        <div className={'flip-card-back-profile'}>
                            <p>
                                <span className="profile-title">{t('profile.label')}:</span><br className={"invisible"} />
                                <span>{t('profile.name')}</span>
                                <br/>
                                <br className={"invisible"} />
                                <span className="profile-title">{t('location.label')}:</span><br className={"invisible"} />
                                <span>{t('location.name')}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ProfileImage);