import NameInput from "./nameInput";
import EmailInput from "./emailInput";
import SubjectInput from "./subjectInput";
import MessageInput from "./messageInput";
import React from "react";
import {Component} from "react/cjs/react.production.min";
import ContactFormAlert from "./contactFormAlert";
import {withTranslation} from "react-i18next";
import * as emailjs from "emailjs-com";

// TODO if spam protection doesnt work, rename real fields to realFieldNameHash, eg. nameJksd23, and rename fake fields to hash-less names
class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {isAlert: false, isFailure: false, isBot: false};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailSuccess = this.handleEmailSuccess.bind(this);
        this.handleEmailFailure = this.handleEmailFailure.bind(this);
    }

    callbackIsAlert = () => {
        this.setState({isAlert: false, isFailure: false});
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.state.isBot) {
            emailjs.sendForm('service_qh0j69i', 'template_a9cm6kr', '#contact-form', 'user_JkA5P1BgCX8Zyjyv9hpOw')
                .then(this.handleEmailSuccess
                    , this.handleEmailFailure)
        } else {
            console.log('Bot detected');
        }
    }

    handleEmailSuccess(response) {
        this.setState({isAlert: true, isFailure: false});
        console.log('SUCCESS!', response.status, response.text);
        document.getElementById("contact-form").reset();
    }

    handleEmailFailure(error) {
        this.setState({isAlert: true, isFailure: true});
        console.log('FAILED...', error);
    }

    render() {
        let alertBox;
        if (this.state.isAlert) {
            if (this.state.isFailure) {
                alertBox = <ContactFormAlert
                    alertClassName={'contact-form-alert-failure'}
                    alertCloseButtonClassName={'contact-form-alert-close-button-failure'}
                    message={'Your email could not be sent. Please try again later or write an email manually.'}
                    callback={this.callbackIsAlert}/>;
            } else {
                alertBox = <ContactFormAlert
                    alertClassName={'contact-form-alert-success'}
                    alertCloseButtonClassName={'contact-form-alert-close-button-success'}
                    message={'Success! I will answer you as soon as possible.'}
                    callback={this.callbackIsAlert}/>;
            }
        }

        const { t } = this.props;

        return (
            <div>
                {alertBox}
                <form id={'contact-form'} onSubmit={this.handleSubmit}>
                    <NameInput/>
                    <EmailInput/>
                    <SubjectInput/>
                    <MessageInput/>
                    <input className={'highlight'} autoComplete={'off;chrome-off'} type={'tel'} name="phone"
                           placeholder="Your phone number here" onChange={() => this.setState({isBot: true})}/>
                    <input className={'highlight'} autoComplete={'off;chrome-off'} type={'text'} name="address"
                           placeholder="Your address here" onChange={() => this.setState({isBot: true})}/>
                    <input type="submit" value={t('contact.form.submit')}/>
                </form>
            </div>
        )
    }
}

export default withTranslation()(ContactForm);