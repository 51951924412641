import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class ServicesItems extends Component {
    render() {
        const { t }  = this.props;

        return (
            <div className="services-items">
                <div className="services-items-row">
                    <div className="services-items-col">
                        <div className={'flip-card'}>
                            <div className={'flip-card-inner'}>
                                <div className={'flip-card-front'}>
                                    <img className={"services-icon"} src={"images/frontend.png"}
                                         alt={"Frontend symbol"}/>
                                    <h3>{t('frontend_development.label1')} {t('frontend_development.label2')}</h3>
                                </div>
                                <div className={'flip-card-back'}>
                                    <p>
                                        {t('frontend_development.text')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-items-col">
                        <div className={'flip-card'}>
                            <div className={'flip-card-inner'}>
                                <div className={'flip-card-front'}>
                                    <img className={"services-icon"} src={"images/backend.png"}
                                         alt={"Backend symbol"}/>
                                    <h3>{t('backend_development.label1')} {t('backend_development.label2')}</h3>
                                </div>
                                <div className={'flip-card-back'}>
                                    <p>
                                        {t('backend_development.text')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-items-col">
                        <div className={'flip-card'}>
                            <div className={'flip-card-inner'}>
                                <div className={'flip-card-front'}>
                                    <img className={"services-icon"} src={"images/software.png"}
                                         alt={"Software symbol"}/>
                                    <h3>{t('software_and_app_development.label')}</h3>
                                </div>
                                <div className={'flip-card-back'}>
                                    <p>
                                        {t('software_and_app_development.text')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-items-col">
                        <div className={'flip-card'}>
                            <div className={'flip-card-inner'}>
                                <div className={'flip-card-front'}>
                                    <img className={"services-icon"} src={"images/consulting.png"}
                                         alt={"Consulting symbol"}/>
                                    <h3>{t('it_consulting.label')}</h3>
                                </div>
                                <div className={'flip-card-back'}>
                                    <p>
                                        {t('it_consulting.text')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ServicesItems);