import React, {Component} from 'react';
import Home from "./home/home";
import About from "./about/about";
import Services from "./services/services";
import Projects from "./projetcs/projects";
import Contact from "./contact/contact";
import Footer from "./footer";

export default class Content extends Component {
    render() {
        return (
            <div id="content">
                <Home element={this.props.element} refs={this.props.refs}/>
                <About refs={this.props.refs}/>
                <Services refs={this.props.refs}/>
                <Projects refs={this.props.refs}/>
                <Contact refs={this.props.refs}/>
                <Footer />
            </div>
        );
    }
}