import React, {Component} from "react";
import {withTranslation} from "react-i18next";

class EmailInput extends Component {

    constructor(props) {
        super(props);
        this.state = {email: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({email: event.target.value});
        // this.props.email(event.target.value)
    }

    render() {
        const { t } = this.props;

        return (
            <input type="email" name="email" placeholder={t('contact.form.email')} onChange={this.handleChange}  required={'required'}/>
        )
    }
}

export default withTranslation()(EmailInput);