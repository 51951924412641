import React, {Component, Suspense} from 'react';
import {Route, Switch} from "react-router-dom";
import Home from "./home/home";
import About from "./about/about";
import Services from "./services/services";
import Projects from "./projetcs/projects";
import Contact from "./contact/contact";
import Imprint from "./imprint";
import Privacy from "./privacy";
import Content from "./content";
import Navigation from "./topbar/navigation";
import ScrollNavigation from "react-single-page-navigation";
import {PuffLoader} from "react-spinners";

export default class Routing extends Component {
    render() {
        return (
            <div id="routing">
                <Switch>
                    <Route exact path="#home" component={Home}/>
                    <Route exact path="#about" component={About}/>
                    <Route exact path="#services" component={Services}/>
                    <Route exact path="#projects" component={Projects}/>
                    <Route exact path="#contact" component={Contact}/>
                    <Route exact path={'/imprint'} render={() => (<Imprint/>)}/>
                    <Route exact path={'/privacy'} render={() => (<Privacy/>)}/>
                    default route is always the app itself
                    <Route render={() => (
                        <ScrollNavigation elements={{Home: {}, About: {}, Services: {}, Projects: {}, Contact: {}}}
                                          offset={-50}>
                            {({refs, activeElement, goTo}) => (
                                <Suspense fallback=
                                              {
                                                  <div className={'center'}>
                                                      <PuffLoader color={'#5c7ad6'} loading={true}/>
                                                  </div>
                                              }>
                                    <div>
                                        <Navigation useSuspense={true}
                                                    refs={refs}
                                                    activeElement={activeElement}
                                                    goTo={goTo}
                                                    isSticky={this.props.isSticky}/>
                                        <Content useSuspense={true}
                                                 element={this.props.element}
                                                 refs={refs}/>
                                    </div>
                                </Suspense>
                            )}
                        </ScrollNavigation>
                    )}/>
                </Switch>
            </div>
        );
    }
}