import React, {Component} from 'react';
import DropdownMenu from "./dropdownMenu";


export default class MobileNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sticky: this.props.sticky
        }
    }

    callbackIsSticky = (isSticky) => {
        this.setState({sticky: isSticky})
    }

    render() {
        return (
            <nav className={this.state.sticky || this.props.sticky ? "mobile-navbar mobile-navbar-sticky" : "mobile-navbar"} id="mainNav">
                <div onClick={() => this.props.goTo('Home')} className="mobile-navbar--logo-holder">
                    <img alt="logo" src={'images/logo_transparent_cut.png'} className="mobile-navbar--logo"/>
                </div>
                <DropdownMenu
                    refs={this.props.refs}
                    activeElement={this.props.activeElement}
                    goTo={this.props.goTo}
                    sticky={this.props.sticky}
                    callback={this.callbackIsSticky}
                />
            </nav>
        );
    }
}